.integrationsFilter {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.filterHeading {
  font-size: 125%;
  font-weight: 600;
}

.filterButton {
  color: var(--cui-color-white);
  background: none;
  border: none;
  flex-direction: row;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 0 20px;
}

.filterContent {
  display: flex;
  flex-direction: column;
}

.filterContent > span {
  display: block;
  margin: 15px 0;
}

.collapseIcon {
  margin-left: 10px;
}

.checkboxWrapper {
  margin: 5px 0;
  text-align: left;
}

.hideFilter {
  display: none;
}
