.actionButton {
  border-color: var(--cui-accent-color) !important;
  color: var(--cui-accent-color) !important;
  background: transparent !important;
  margin: 2px 4px 8px 0;
  padding: 2px 36px !important;
  font-size: 1.3rem !important;
  line-height: 1.8 !important;
}

.actionButton:hover {
  background: var(--cui-accent-color) !important;
  color: var(--cui-color-white) !important;
  border-color: var(--cui-accent-color) !important;
}
