.root {
  width: 120px;
  height: 120px;
  cursor: pointer;
  margin: 2px;
  text-align: center;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 10px;
  justify-content: center;
  align-items: flex-end;
}

:global(.cui[data-theme='dark']) .isCustomIconSelected {
  background-color: var(--tb-blue-active);
}

:global(.cui[data-theme='light']) .isCustomIconSelected {
  background-color: var(--cui-color-gray-200);
}

.iconImage {
  width: 80px;
  height: 60px;
  min-width: 80px;
  min-height: 60px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  padding: 8px;
}

.iconLabel {
  font-size: smaller;
}
