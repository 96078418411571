.root {
  --hero-padding-top-bottom: 60px;
  width: 100%;
  padding-top: var(--hero-padding-top-bottom);
  padding-bottom: var(--hero-padding-top-bottom);
}

.content {
  align-items: center;
}

.container {
  display: flex;
  justify-content: space-between;
  height: 100%;
}

.image {
  display: flex;
  flex-direction: column;
}

.image img {
  margin: auto;
  width: 100%;
}

.webexImg img {
  margin: auto;
  width: 65%;
}

.link:hover {
  text-decoration: none !important;
}

.getStarted {
  display: inline-block;
  width: 167px;
}

:global(.cui) .subheading {
  line-height: 1.5;
}
