.root {
  width: 100px;
  height: 100px;
  text-align: center;
}

.root svg {
  width: 80%;
  margin: 0 auto;
}

.svgCorrection svg {
  margin-top: -15px;
}

:global(.cui) .title {
  color: var(--cui-color-sky);
  margin: 0;
}

.actionButtonsWrapper {
  display: none;
  position: absolute;
  bottom: -10px;
  margin: auto;
  width: 100%;
  z-index: var(--tb-diagram-z-index-edit);
}

.root:hover .actionButtonsWrapper {
  display: block;
}
