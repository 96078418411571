.outer {
  width: 100%;
  overflow: auto;
}

.root {
  width: 100%;
  min-width: 1550px;
  overflow: visible;
}

:global(.cui .truncate).name {
  display: block;
}
