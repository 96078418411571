.root {
  --hero-padding-top-bottom: 60px;
  width: 100%;
  padding-top: var(--hero-padding-top-bottom);
  padding-bottom: var(--hero-padding-top-bottom);
}

.content {
  align-items: center;
}

.container {
  display: flex;
  justify-content: space-between;
  height: 100%;
}

.image {
  display: flex;
  flex-direction: column;
}

.image img {
  margin: auto;
  width: 100%;
}

.getStarted {
  display: inline-block;
}

:global(.cui) .subheading {
  line-height: 1.5;
}
