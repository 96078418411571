.topologyTitle {
  max-width: calc(50vw - 450px);
  margin-left: 16px;
  font-size: var(--cui-font-size);
  align-self: center;
  color: var(--cui-color-gray-500);
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

@media (max-width: 1500px) {
  .topologyTitle {
    max-width: 450px;
  }
}

@media (max-width: 1400px) {
  .topologyTitle {
    max-width: 350px;
  }
}

@media (max-width: 1300px) {
  .topologyTitle {
    max-width: 200px;
  }
}

@media (max-width: 1200px) {
  .topologyTitle {
    display: none;
  }
}
