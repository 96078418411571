.wrapper {
  transition: all 0.2s ease-out;
  display: flex;
  align-items: center;
  justify-content: right;
  margin-bottom: -20px;
  opacity: 0;
  height: 0;
  padding-right: 8px;
}

.wrapperShow {
  transition: all 0.2s ease-out;
  display: flex;
  align-items: center;
  justify-content: right;
  margin-bottom: 10px;
  opacity: 1;
  height: 30px;
  padding-right: 8px;
}

.label {
  margin-right: 10px;
  width: 100%;
  text-align: right;
}

.icon {
  color: var(--cui-color-white);
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
