.root {
  margin-bottom: var(--cui-spacing-dbl);
}

.natInfo {
  display: flex;
}

.natInfo :global(.form-group) {
  width: calc(var(--cui-spacing) * 12);
}

:global(.cui) .alert {
  align-self: center;
  margin: 0;
}

:global(.cui) .natTypeInput {
  width: calc(var(--cui-spacing-dbl) * 6 + 30px);
}
