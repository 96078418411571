.root {
  --sidebar-background-color: var(--cui-color-gray-300);
  --selected-item-background-color: #1f2d3f;
  display: flex;
  min-height: calc(100vh - 50px);
  margin-top: 50px;
}

:global(.cui[data-theme='dark']) .root {
  --sidebar-background-color: var(--cui-color-gray-300);
  --sidebar-background-color: #06101e;
}

:global(.cui .content).root {
  --offset-to-stop-scroll: 5px;
  display: flex;
}

.sidebar {
  background-color: var(--sidebar-background-color);
  min-width: 250px;
  padding-top: 30px;
}

.content {
  width: calc(100% - 250px);
  padding: var(--cui-spacing-dbl) var(--cui-spacing-dbl)
    var(--tb-help-button-padding-bottom) var(--cui-spacing-dbl);
}

:global(.cui) .sidebar :global(.tabs) {
  border: none;
}

:global(.cui) .sidebar :global(.tabs > li > a),
:global(.cui) .sidebar :global(.tabs > li > button) {
  padding-left: 30px;
}

:global(.cui[data-theme='dark'])
  .sidebar
  :global(.tabs.tabs--vertical .active a) {
  background-color: var(--selected-item-background-color);
}

:global(.cui[data-theme='dark'])
  .sidebar
  :global(.tabs.tabs--vertical a:hover) {
  background-color: var(--tb-darkest-blue);
}
