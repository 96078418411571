.label {
  margin: 0 4px 2px 0 !important;
}

.pickerContainer {
  border: 1px solid transparent;
  margin-top: 3px;
  padding: 10px;
  background-color: var(--tb-blue-active);
  min-height: 50px;
}

.pickerContainer:hover {
  border: 1px solid var(--cui-theme-primary-darker);
}

.pickerContainer span {
  cursor: pointer;
}
