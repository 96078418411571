.root {
  height: calc(100vh - var(--cui-header-height) * 2);
  width: 450px;
  position: absolute;
  top: calc(var(--cui-header-height) * 2);
  background-color: var(--tb-blue);
  overflow: auto;
  z-index: calc(var(--tb-diagram-z-index-left-panel));
}

:global(.cui[data-theme='light']) .root {
  background-color: var(--cui-color-gray-100);
}
