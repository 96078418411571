.tagContainer {
  border: 1px solid transparent;
  align-items: center;
  background: var(--tb-blue-active);
  border-radius: 3px;
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  line-height: 1.4;
  padding: 7px 10px;
  min-height: 50px;
  max-height: 105px;
  overflow-y: auto;
}

:global(.cui[data-theme='light']) .tagContainer,
:global(.cui[data-theme='light']) .tagInput {
  background-color: var(--cui-color-gray-300);
}

.tagInput {
  border: 0;
  outline: 0;
  font-size: inherit;
  line-height: inherit;
  width: 60%;
  color: var(--cui-color-black);
  background-color: var(--tb-blue-active);
}

.tagFullWidth {
  width: 100%;
}

.tag {
  align-items: center;
  background: var(--cui-color-gray-700);
  border-radius: 3px;
  display: inline-flex;
  justify-content: center;
  height: var(--cui-label-small-height) !important;
  padding: 1px 6px !important;
  font-size: 12px !important;
  margin: 2px;
  color: var(--cui-color-white);
}

.tag button {
  background: none;
  border: 0;
  border-radius: 50%;
  cursor: pointer;
  line-height: inherit;
  padding: 0 0.5rem;
}

.tag button span {
  color: var(--cui-color-gray-500) !important;
  font-size: var(--cui-font-size-xs);
  font-weight: 700;
}

.tag button span:hover {
  color: var(--cui-color-white) !important;
}

.errorTextfield {
  border: 1px solid var(--cui-color-danger);
}
