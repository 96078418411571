.root {
  --back-button-color: var(--tb-darkest-blue);
  --back-button-hover-bg: var(--tb-darkest-blue);
  --back-button-hover-color: var(--cui-color-white);
}

:global(.cui[data-theme='dark']) .root {
  --back-button-color: var(--cui-color-white);
  --back-button-hover-bg: var(--cui-color-white);
  --back-button-hover-color: var(--tb-darkest-blue);
}

:global(.cui a:not(.btn)).root {
  width: 30px;
  height: 30px;
  color: var(--back-button-color);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin-right: var(--cui-spacing);
  border: 1px solid var(--back-button-color);
  font-size: 11px;
  position: relative;
  overflow: hidden;
  transition: color 0.3s;
}

:global(.cui a:not(.btn)).root:hover {
  color: var(--back-button-hover-color);
}

.root:before {
  content: '';
  width: 100%;
  height: 100%;
  background-color: var(--back-button-hover-bg);
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(-100%, -100%) rotate(-45deg);
  transition: transform 0.3s;
}

.root:hover:before {
  transform: translate(0, 0) rotate(-45deg);
}

.backButtonOverride {
  margin-right: var(--cui-spacing);
}

:global(.cui[data-theme='dark']) .backButtonOverride {
  background-color: transparent;
  border: 1px solid var(--cui-color-white);
  color: var(--cui-color-white);
}

:global(.cui[data-theme='light']) .backButtonOverride {
  background-color: transparent;
  border: 1px solid var(--tb-darkest-blue);
  color: var(--tb-darkest-blue);
}
