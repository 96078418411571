.root {
  display: flex;
  margin-bottom: 50px;
}

.formSection {
  margin-right: var(--cui-spacing);
}

:global(.cui .form-group).checkbox {
  margin-top: var(--cui-spacing);
}

.button {
  margin-top: 30px;
}

.errorMaxLimitForNetwork {
  margin: -40px 10px 18px 10px;
  color: var(--cui-inactive-color);
  font-size: var(--cui-form-help-font-size);
}

.errorSelect select {
  box-shadow: 0 0 0 var(--cui-focus-thickness)
    rgba(var(--cui-theme-danger-rgb), var(--cui-focus-opacity)) !important;
}

.checkboxGroup {
  display: flex;
  flex-direction: column;
  align-items: center;
}
