.root {
  display: flex;
}

.iconPickerInModal {
  z-index: 1041 !important;
}

:global(.cui) .root :global(.form-group) {
  margin-bottom: var(--cui-spacing);
}

.iconGroup {
  display: flex;
  align-items: center;
  margin-top: 25px;
}

.vmIcon {
  width: 100px;
  height: 100px;
  min-width: 100px;
  min-height: 100px;
}

.changeIconButton {
  margin: 30px;
  width: 200px;
  height: 40px;
  margin-left: 20px;
  white-space: nowrap;
}
