.root {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
}

.pointerEventsAuto {
  pointer-events: auto;
}

.pointerEventsNone {
  pointer-events: none;
}
