.root {
  display: inline-block;
  position: absolute;
  border: 2px solid transparent;
  padding: 5px;
  transform-origin: 0 0;
  z-index: var(--tb-diagram-z-index-asset-nodes);
  pointer-events: auto;
}

:global(.cui[data-theme='light']) .radialGradient {
  background: radial-gradient(
    var(--cui-color-white)
      var(--tb-diagram-icon-radial-gradient-start-percentage),
    transparent var(--tb-diagram-icon-radial-gradient-end-percentage)
  );
}

:global(.cui[data-theme='dark']) .radialGradient {
  background: radial-gradient(
    var(--tb-diagram-background-colour)
      var(--tb-diagram-icon-radial-gradient-start-percentage),
    transparent var(--tb-diagram-icon-radial-gradient-end-percentage)
  );
}

.removableNode:focus {
  outline: none;
}

.root.drawNode {
  z-index: var(--tb-diagram-z-index-drawing-nodes);
}

.selected.drawNode {
  z-index: var(--tb-diagram-z-index-selected);
}

.disabled {
  opacity: 0.45;
}

.hidden {
  opacity: 0;
}

.handle {
  display: none;
  width: 15px;
  height: 15px;
  background-color: var(--cui-color-white);
  position: absolute;
  top: 50%;
  left: -9px;
  transform: translateY(-50%);
}

.handle:nth-child(2) {
  right: -9px;
  left: auto;
}

.selected .handle {
  display: block;
}

.editable {
  cursor: grab;
}

.editable:active {
  cursor: grabbing;
}

.notAllowed {
  cursor: not-allowed;
}
