.root {
  position: absolute;
  bottom: 120px;
  right: 30px;
}

.buttonWrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.hideLinks > div {
  height: 0;
  width: 0;
  transition: height, width 0s;
  transition-delay: 1s;
}

.parentButton {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.icon {
  color: var(--cui-color-white);
}
