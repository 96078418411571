.label {
  padding: 0 6px !important;
  margin: 2px 4px !important;
}

.label :global(.icon-close) {
  transition: color 0.2s;
  color: var(--cui-color-gray-500) !important;
}

.label :global(.icon-close:hover) {
  color: var(--cui-color-white) !important;
}

.label :global(.icon-close) {
  color: var(--cui-color-gray-300) !important;
}

.label :global(.icon-close):hover {
  color: var(--cui-color-white) !important;
}
