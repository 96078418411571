.root :global(.modal__dialog) {
  display: flex;
  height: auto;
}

.container {
  margin-top: var(--cui-spacing-dbl);
}

.addBtn {
  height: 4rem;
  margin: auto 0;
}
