.a {
  min-width: 290px;
}

:global(.cui[data-theme='light']) .requestVmButton {
  color: var(--cui-font-color) !important;
}

:global(.cui[data-theme='light']) .requestVmButton:hover {
  color: white !important;
}
