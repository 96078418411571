.menuButton {
  background: transparent;
  border: none;
  cursor: pointer;
  transform: rotate(90deg);
}

.activeDropDownMenu {
  box-shadow: var(--cui-shadow-outset);
}

.activeDropDown {
  position: absolute !important;
}
