.tableView {
  max-width: 95%;
  margin-left: auto;
  margin-right: auto;
}

.cardView {
  max-width: 1800px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.root :global(.cui .form-group) {
  min-width: 350px;
  margin-bottom: 0;
}

.searchWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0;
  padding: 0;
}

.searchFeedback {
  font-size: 85%;
  opacity: 1;
  color: var(--cui-inactive-color);
}

.searchFeedbackHidden {
  font-size: 85%;
  opacity: 0;
}
