.skeletonLoader {
  width: 120px;
  height: 120px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: var(--tb-diagram-z-index-loader);
}

.skeletonLoader:hover {
  border-color: transparent;
}
