.root {
  width: 120px;
  min-height: 140px;
  text-align: center;
}

.root svg {
  width: 65%;
  margin: 0 auto;
}

.title {
  background-color: var(--cui-background-color);
  padding: 3px;
  font-size: 90%;
}

.root:hover .editIconWrapper {
  display: block;
}

.addingLogicalConnectionGlow {
  filter: drop-shadow(0 0 9px var(--cui-color-sky));
  cursor: pointer;
}

.addingLogicalConnectionGlow:hover .glowIcon {
  filter: drop-shadow(0 0 9px var(--cui-color-sky));
  position: relative;
}

.hwNode:hover {
  background: transparent;
  border-color: transparent;
}

.iconsWrapper {
  position: relative;
}

:global(.cui[data-theme='light']) .editIconWrapper {
  border: 2px solid var(--cui-color-gray-200);
  color: var(--cui-color-white);
}

.actionButtonsWrapper {
  display: none;
  position: absolute;
  bottom: 0px;
  left: calc(50%);
  z-index: var(--tb-diagram-z-index-edit);
}

.root:hover .actionButtonsWrapper {
  display: block;
}
