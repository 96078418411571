.root {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  width: 100px;
  height: auto;
  text-align: center;
  border-radius: 10px;
  padding: 10px;
  cursor: pointer;
  overflow: hidden;
}

:global(.cui[data-theme='dark']) .isIconPickerIconSelected {
  background-color: var(--tb-blue-active);
}

:global(.cui[data-theme='light']) .isIconPickerIconSelected {
  background-color: var(--cui-color-gray-200);
}

.iconImage {
  width: 75%;
  padding: 0px;
  margin: auto;
}

.iconLabel {
  width: 100%;
  height: 40px;
  font-size: smaller;
}
