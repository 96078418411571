.tagCloud,
.tagCloud:hover,
.tagCloud:focus,
.tagCloud:focus-within {
  background: transparent !important;
  border: 0 !important;
}

.suggestionBox {
  background: var(--tb-blue-active);
  border-radius: 0 0 4px 4px;
  padding: 0 8px 8px 8px;
  margin-top: -17px;
  margin-left: 8px;
  margin-right: 8px;
}

:global(.cui[data-theme='light']) .suggestionBox {
  background-color: var(--cui-color-gray-300);
}

.suggestionError {
  color: var(--cui-color-gray-500);
  font-size: 88%;
}

:global(.cui[data-theme='light']) .suggestionError {
  color: var(--cui-color-black);
}
