.root {
  width: 120px;
  min-height: 150px;
  text-align: center;
}

.root svg {
  width: 65%;
  margin: 0 auto;
}

.title {
  background-color: var(--cui-background-color);
  padding: 3px;
  font-size: 90%;
}

.root svg.anyconnectIcon {
  position: absolute;
  width: 20%;
  left: 20px;
  top: 90px;
}

.actionButtonsWrapper {
  display: none;
  position: absolute;
  bottom: 10px;
  margin: auto;
  width: 100%;
  z-index: var(--tb-diagram-z-index-edit);
}

.root:hover .actionButtonsWrapper {
  display: block;
}
