.settingsIcon {
    color: var(--cui-color-white);
    margin: 0 20px;
    text-decoration: none !important;
    font-size: 2.1rem !important;
}

.settingsLink {
    opacity: .75;
    transition: .5s opacity;
}

.settingsLink:hover, .settingsLink:focus, .settingsLink:active {
    text-decoration: none !important;
    opacity: 1;
}

.infoModal li {
    margin-bottom: 24px;
}

.infoModal li > span:first-of-type {
    font-size: 1.75rem !important;
    font-weight: normal;
}

.infoModal li > span:nth-of-type(2) div {
    display: inline-block !important;
    background: var(--cui-color-black) !important;
    border-radius: 10px !important;
    padding: 4px 10px !important;
    color: var(--cui-color-white) !important;
    margin: 2px 4px;
    font-size: 1.4rem !important;
}

.closeButton {
    display: none !important;
}