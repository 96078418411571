:global(.cui) .root :global(.btn) {
  background-color: var(--tb-blue-active);
  border: none;
  color: white;
  font-size: 1.2rem;
  padding: 0;
  width: 10rem;
}

:global(.cui) .root :global(.btn):nth-child(n + 2) {
  margin-left: 2px;
}

:global(.cui) .icon :global(.btn) {
  font-size: 1.5rem;
  width: 5rem;
}
