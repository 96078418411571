.svgContainer {
  height: 30px;
}

.integrationAccordion svg {
  width: 36px;
  height: 30px;
  margin-right: 8px;
}

.integrationAccordion > span {
  margin-left: auto;
}

.accordionAlerts {
  margin-top: -10px;
}
