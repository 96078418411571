.root {
  position: absolute;
  left: calc(50% + 4px);
  bottom: 10px;
  padding: 3px 10px 4px 10px;
  background: var(--cui-theme-danger);
  filter: drop-shadow(0 0 3px var(--tb-darkest-blue));
  border-radius: 16px;
  line-height: 1;
  z-index: var(--tb-diagram-z-index-edit);
  cursor: pointer;
}

.root:hover {
  filter: drop-shadow(0 0 9px var(--cui-theme-danger));
}

:global(.cui[data-theme='light']) .root {
  border: 2px solid var(--cui-color-gray-200);
}

.icon {
  color: var(--cui-color-white);
  font-size: 12px;
}
