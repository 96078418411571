:global(.cui) .root :global(.modal__dialog) {
  width: 1300px;
}

:global(.cui) .root :global(.modal__content) {
  height: auto;
  width: 100%;
}

:global(.cui) .root :global(.modal__title h2) {
  --cui-modal-title-font-size: 30px;
}

.container {
  width: 90%;
  margin: 0 auto;
  padding-top: 20px;
}

.colsContainer {
  display: flex;
}

.col {
  flex: 1;
  margin-right: 0;
}

.col:not(:first-child) {
  padding-left: 30px;
}

.col:not(:last-child) {
  padding-right: 30px;
}

.col:not(:last-child) {
  border-right: 2px solid var(--cui-theme-primary-darker);
}

.architectureLabels {
  margin: 2px 4px 2px 0 !important;
}

.descriptionLabel {
  font-weight: bold;
  margin-right: 15px;
}
