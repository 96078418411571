.selected {
  z-index: var(--tb-diagram-z-index-selected);
  cursor: grab;
}

.diagramTextNode,
:global(.cui[data-theme='light']) .diagramTextNode {
  border: 2px solid transparent !important;
  z-index: var(--tb-diagram-z-index-text-nodes) !important;
}

.diagramTextNode .selected {
  outline: 2px solid var(--cui-color-sky);
}

.editIconWrapper {
  position: absolute;
  left: calc(50% - 17px);
  bottom: -26px;
  padding: 3px 10px 4px 10px;
  background: var(--cui-color-sky);
  filter: drop-shadow(0 0 3px var(--tb-darkest-blue));
  border-radius: 16px;
  line-height: 1;
  opacity: 0;
  cursor: pointer;
  z-index: var(--tb-diagram-z-index-edit);
}

.editIconWrapper:hover {
  filter: drop-shadow(0 0 9px var(--cui-color-sky));
}

.diagramTextNode:hover .editIconWrapper,
.diagramTextNode.selected .editIconWrapper {
  opacity: 1;
}

:global(.cui[data-theme='light']) .editIconWrapper {
  border: 2px solid var(--cui-color-gray-200);
  color: var(--cui-color-white);
}

.label {
  white-space: pre-wrap;
  padding: 0 15px 0 15px;
  max-width: var(--tb-diagram-text-width);
  font-size: var(--tb-diagram-text-font-size);
  word-break: break-word;
}

.colorDanger, :global(.cui[data-theme='light']) .colorDanger {
  color: var(--cui-color-danger);
}

.colorSuccess, :global(.cui[data-theme='light']) .colorSuccess {
  color: var(--cui-color-success);
}

.colorWarningAlt, :global(.cui[data-theme='light']) .colorWarningAlt {
  color: var(--cui-color-warning-alt);
}

.colorWarning, :global(.cui[data-theme='light']) .colorWarning {
  color: var(--cui-color-warning);
}

.colorOcean, :global(.cui[data-theme='light']) .colorOcean {
  color: var(--cui-color-ocean);
}

.colorLink, :global(.cui[data-theme='light']) .colorLink {
  color: var(--cui-color-link);
}

.colorSky, :global(.cui[data-theme='light']) .colorSky {
  color: var(--cui-color-sky);
}

.colorBlack, :global(.cui[data-theme='light']) .colorBlack {
  color: var(--cui-color-black);
}

.colorGray200, :global(.cui[data-theme='dark']) .colorGray200 {
  color: var(--cui-color-gray-200);
}

:global(.cui[data-theme='light']) .colorGray200 {
  color: var(--cui-color-gray-600);
}

.colorGray400, :global(.cui[data-theme='light']) .colorGray400 {
  color: var(--cui-color-gray-400);
}

.colorGray600, :global(.cui[data-theme='light']) .colorGray600 {
  color: var(--cui-color-gray-600);
}

.colorGray800, :global(.cui[data-theme='light']) .colorGray800 {
  color: var(--cui-color-gray-800);
}

.rotateIcon {
  display: flex;
  position: absolute;
  left: calc(50% - 18px);
  top: -34px
}

.textNodeText {
  transform-origin: center center;
  padding: 6px 12px;
  margin: -6px -12px;
}
