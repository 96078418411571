.root {
  width: 120px;
  height: 130px;
  text-align: center;
}

.root svg {
  width: 65%;
  margin: 0 auto;
}

.title {
  background-color: var(--cui-background-color);
  padding: 3px;
  font-size: 90%;
}

.buttons {
  display: none;
}

.root:hover .buttons {
  display: block;
}

.root:hover .glow {
  filter: drop-shadow(0 0 9px var(--cui-color-sky));
}

.addingLogicalConnectionGlow {
  filter: drop-shadow(0 0 9px var(--cui-color-sky));
  cursor: pointer;
}
