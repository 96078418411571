.root {
  margin-bottom: var(--cui-spacing-dbl);
}

:global(.cui) .vmInput {
  width: calc(var(--cui-spacing-dbl) * 6);
}

:global(.cui) .ipAddressInput {
  width: calc(var(--cui-spacing-dbl) * 6);
}

:global(.cui) .assetInput {
  width: calc(var(--cui-spacing-dbl) * 6);
}

:global(.cui) .alert {
  align-self: center;
  margin: 0;
}
