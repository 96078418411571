.scheduleButton {
  padding: 0 25px;
  font-size: var(--cui-font-size-lg);
  border: var(--add-btn-background);
  cursor: pointer;
  pointer-events: initial;
  height: var(--tb-header-btn-height);
  border-radius: var(--cui-border-radius-round);
  margin-right: 10px;
  height: 30px;
}

.disabledScheduleButton {
  color: var(--cui-font-color);
  background-color: var(--cui-color-gray-600);
  opacity: 0.5;
  cursor: not-allowed;
}

.disabledScheduleButtonWrapper {
  display: flex;
  align-items: center;
  gap: 12px;
}

.icon {
  font-size: 18px;
  color: var(--cui-color-gray-600);
}

.inSync {
  background-color: var(--cui-color-success);
}

.notInSync {
  background-color: var(--cui-color-warning);
}
