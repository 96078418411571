.root {
  margin-top: var(--cui-header-height);
  height: var(--cui-header-height);
  display: flex;
}

:global(.cui[data-theme='dark'] .header).root {
  background-color: var(--tb-session-view-actions-background);
}

:global(.cui[data-theme='light'] .header).root {
  background-color: white;
}
