.carousel {
  overflow: hidden;
  max-width: calc(var(--tb-content-width));
  margin: 0 auto;
}

.inner {
  max-width: calc(var(--tb-content-width));
  transition: transform 0.3s;
  display: -webkit-inline-box;
  display: -moz-inline-box;
}

.indicators {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.indicatorButtons {
  border: none;
  cursor: pointer;
}

.indicatorButtons:first-child {
  display: none;
}

.indicatorButtons:last-child {
  display: none;
}

.indicators > button {
  margin: 5px;
  background: none;
}

.indicatorSymbol {
  color: #26343f;
}

.indicatorSymbolActive {
  color: #ffffff;
}
