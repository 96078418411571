.hide {
  display: none;
}

.spaceBelow {
  margin-bottom: 20px;
}

.remoteAccessRow {
  margin-bottom: 30px;
}

.remoteAccessDisplayAltCredsRow {
  margin-bottom: 0;
}

.displayAltCredsClosedPadding {
  margin-bottom: 45px;
}

.remoteAccessDisplayCredsRow {
  margin-bottom: 50px;
}

.alert {
  padding: 0 !important;
}
