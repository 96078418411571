.root {
  stroke-width: 10;
  cursor: pointer;
}

.root:hover {
  stroke: var(--cui-color-gray-400);
  opacity: 0.3;
}

.selected {
  stroke: var(--cui-color-gray-400);
  opacity: 0.3;
}
