.scrollTop {
  display: none;
  position: fixed;
  bottom: 120px;
  right: 30px;
}

.scrollToTopButton {
  color: #fff;
  opacity: 0.85;
  transition: background-color 0.1s;
  border: 1px solid #152e47 !important;
  background: #07111d !important;
  border-radius: 50px !important;
  width: 54px !important;
  height: 54px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.scrollToTopButton:hover {
  opacity: 1;
  background: var(--cui-theme-primary) !important;
  border-color: var(--cui-theme-primary) !important;
}

.scrollToTopIcon {
  padding: 0 2px;
}

.visible {
  display: block;
}
