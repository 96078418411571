.wrapper {
  min-width: var(--tb-content-width);
}

.content {
  max-width: 95%;
  margin: var(--cui-spacing-dbl) auto 0 auto;
}

.table {
  max-width: 95%;
  margin-left: auto;
  margin-right: auto;
}

.tag {
  background: black;
  display: inline-block;
  padding: 1px 16px;
  margin: 4px;
  border-radius: 3px;
  color: white;
}

.green {
  color: var(--cui-color-success);
  font-size: 2rem !important;
}

.red {
  color: var(--cui-color-danger);
  font-size: 1.9rem !important;
}

:global .ag-cell-not-inline-editing {
  padding: 12px 8px !important;
}

.addFormButtons {
  height: 40px;
  color: var(--cui-color-white) !important;
  min-width: 120px;
}

:global(.cui[data-theme='light']) .addFormButtons {
  color: var(--cui-color-black) !important;
}

.form {
  width: 70%;
}

.errorIcon {
  color: var(--cui-color-danger);
}

.successIcon {
  color: var(--cui-theme-success);
}

.integrationsHeaderContainer {
  min-height: 100px;
}

.searchContainer {
  flex-direction: column;
}

.searchWarning {
  font-size: 85%;
  color: var(--cui-inactive-color);
  text-align: center;
  margin-top: 10px;
}
