.root {
  --network-colour: var(--cui-color-midnight);
  --network-name-colour: var(--cui-color-midnight);
  --network-name-background-colour: #f2f2f2;
  text-align: center;
  color: var(--network-colour);
  min-width: 200px;
  min-height: 125px;
}

:global(.cui[data-theme='dark']) .root {
  --network-colour: var(--cui-color-white);
  --network-name-background-colour: #333;
  --network-name-colour: var(--cui-color-white);
}

.line {
  display: block;
  width: 100%;
  height: 30px;
  border-bottom: 2px solid var(--network-colour);
  position: relative;
}

.line:before,
.line:after {
  content: '';
  display: block;
  width: 2px;
  height: 15px;
  background-color: var(--network-colour);
  position: absolute;
  bottom: -2px;
}

.line:after {
  right: 0;
}

.nameContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.name {
  margin: 0 !important;
  background-color: var(--network-name-background-colour);
  color: var(--network-name-colour);
  flex: 1;
  padding: 3px 10px;
  box-shadow: 0px 4px 4px 0px #00000040;
}

.subnet {
  flex: 2;
  font-size: 0.7em;
}

:global(.cui[data-theme='light']) .editIconWrapper {
  border: 2px solid var(--cui-color-gray-200);
  color: var(--cui-color-white);
}

.actionButtonsWrapper {
  display: none;
  position: absolute;
  bottom: 0px;
  left: calc(50%);
  z-index: var(--tb-diagram-z-index-edit);
}

.root:hover .actionButtonsWrapper {
  display: block;
}
