.root {
  position: fixed;
  z-index: 10000;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  align-items: center;
  justify-content: center;
}
