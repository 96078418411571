.pickerContainer {
  padding: 10px;
  background-color: var(--tb-blue-active);
  overflow-y: auto;
  max-height: 105px;
  min-height: 50px;
  border: 1px solid transparent;
}

:global(.cui[data-theme='light']) .pickerContainer {
  background-color: var(--cui-color-gray-300);
}

.pickerContainer:hover {
  border: 1px solid var(--cui-theme-primary-darker);
}

.pickerContainer span {
  cursor: pointer;
}
