:global(.cui a).nameLink {
  display: inline-block;
  height: 100%;
  width: 100%;
}

:global(.cui .btn.btn--link).nameButton {
  color: var(--cui-link-color);
  padding: 0;
  line-height: 21px;
}

:global(.cui .btn.btn--link).nameButton:hover {
  color: var(--cui-link-color);
  text-decoration: underline;
}
