.root {
  width: 120px;
  min-height: 120px;
  text-align: center;
}

.root svg {
  width: 65%;
  margin-top: -10px;
}

:global(.cui) .title {
  background-color: var(--cui-background-color);
  padding: 3px;
  font-size: 90%;
}

.root .osIcon {
  position: absolute;
  width: 20%;
  left: 10px;
  top: 65px;
}

.root .ssh {
  background-color: var(--cui-color-warning-alt);
}

.root .rdp {
  background-color: var(--cui-color-info);
}

.root .con {
  background-color: var(--cui-color-success);
}

.vmNode:hover {
  background: transparent;
  border-color: transparent;
}

:global(.cui[data-theme='light']) .actionButtonsWrapper {
  border: 2px solid var(--cui-color-gray-200);
}

.actionButtonsWrapper {
  display: none;
  position: absolute;
  bottom: -10px;
  left: calc(50%);
}

.vmNode:hover .actionButtonsWrapper {
  display: block;
}

.addingLogicalConnectionGlow:hover .glow {
  filter: drop-shadow(0 0 9px var(--cui-color-sky));
}

.vmIconsWrapper {
  position: relative;
}

.vmIconLabel {
  border-radius: 2px;
  font-size: 0.8rem;
  width: 30px;
  padding: 1px;
}

.flexWrapper {
  display: flex;
  height: 100px;
  width: 72px;
  flex-direction: column;
  position: absolute;
  right: -42px;
  top: 2px;
  flex-wrap: wrap;
}

.flexItem {
  width: 36px;
  max-height: 32px;
  padding-bottom: 6px;
  padding-right: 6px;
  color: var(--cui-color-black);
}

svg.natProxyIcon {
  width: 26px;
  height: 26px;
  margin: 0;
  color: var(--cui-color-white);
}

:global(.cui[data-theme='light']) svg.natProxyIcon {
  color: var(--cui-color-gray-600);
}

.addingLogicalConnectionGlow {
  filter: drop-shadow(0 0 9px var(--cui-color-sky));
  cursor: pointer;
}
