.icon {
  width: 80px;
  height: 60px;
  min-width: 80px;
  min-height: 60px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  padding: 8px;
}

.iconGroup {
  display: flex;
  align-items: center;
}

.changeIconButton {
  margin-left: 20px;
  width: 200px;
  height: 40px;
  white-space: nowrap;
}
