:global(.cui) .root,
:global(.cui) .root:focus {
  background: transparent;
  border: 0;
  color: var(--cui-color-gray-500);
  box-shadow: none;
}

:global(.cui[data-theme='light']) .root {
  color: var(--cui-color-gray-600);
}

:global(.cui[data-theme='light']) .root:hover {
  color: var(--cui-color-gray-800);
}

:global(.cui) .root:hover,
:global(.cui) .root:active {
  color: var(--cui-color-white);
  background: transparent;
  box-shadow: none;
}

:global(.cui) .root:active {
  cursor: grabbing;
  transform: scale(0.9);
  opacity: 0.8;
}

:global(.cui) .root:focus {
  border: 0;
}

:global(.cui[data-theme='light']) .root:hover,
:global(.cui[data-theme='light']) .root:active {
  color: var(--cui-color-gray-900);
}
