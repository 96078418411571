.root {
  background: transparent;
  width: 100px;
  height: 10px;
  position: absolute;
  top: 0;
  left: 0;
  padding: 0;
  border: 0;
}

.root:hover {
  border: 0;
}

.handles {
  width: 100%;
  height: 2px;
  box-sizing: border-box;
  z-index: var(--tb-diagram-z-index-handles);
}

.root .handles .selected {
  border: 2px solid var(--cui-color-danger);
}

.root .handles .handle {
  width: 10px;
  height: 10px;
  background: var(--cui-color-white);
  border: 2px solid var(--cui-color-sky);
  border-radius: 100%;
  position: absolute;
  visibility: hidden;
}

.root .handles .handle.left {
  left: 0;
  top: 0;
  cursor: ew-resize;
}

.root .handles .handle.selected {
  visibility: visible;
}

.root .handles .handle.right {
  left: 0;
  top: 0;
  cursor: ew-resize;
}

.lineSelector {
  position: absolute;
  border-radius: 8px;
  cursor: pointer;
  visibility: visible;
}

.hidden {
  visibility: hidden;
}
