.root {
  width: 120px;
  min-height: 150px;
  text-align: center;
}

.root svg {
  width: 65%;
  margin: 0 auto;
}

.svgCorrection svg {
  width: 52%;
  margin-top: 15px;
}

.title {
  background-color: var(--cui-background-color);
  padding: 3px;
  font-size: 90%;
}

.actionButtonsWrapper {
  display: none;
  position: absolute;
  bottom: 20px;
  margin: auto;
  width: 100%;
  z-index: var(--tb-diagram-z-index-edit);
}

.root:hover .actionButtonsWrapper {
  display: block;
}
