.worklowparamDescription {
  font-size: small;
  color: var(--cui-color-gray-500) !important;
  margin: 5px 0 20px 0 !important;
}

.defaultToTemplateCheckbox {
  display: flex;
  margin-top: 90px;
  flex-direction: column;
}
