.diagramDeviceEditModal :global(.backButton) {
  display: none !important;
}

.diagramDeviceEditModal :global(.accordions) {
  overflow: auto;
  max-height: calc(100vh - 300px);
  padding-right: 6px;
}

:global(.cui[data-theme='dark'])
  .diagramDeviceEditModal
  :global(.accordion-row-button) {
  background-color: var(--tb-darkest-blue);
}

:global(.cui[data-theme='dark'])
  .diagramDeviceEditModal
  :global(.accordion-row-button:hover) {
  background-color: var(--tb-blue-active);
}
