.root {
  justify-content: flex-start;
}

.row {
  display: flex;
  flex-direction: row;
}

.cell {
  border: 1px solid gray;
  padding: 10px;
  flex: 1;
  word-break: break-word;
}

.bold {
  font-weight: bold;
}

.wideCell2 {
  flex: 1.8;
}

.wideCell3 {
  flex: 3;
}

.wideCell4 {
  flex: 4;
}

.wideCell5 {
  flex: 5;
}

.wideCell6 {
  flex: 6;
}

.valueRow {
  display: flex;
}

.valueCol1 {
  color: var(--cui-color-gray-500);
  font-size: 1.38rem;
  width: calc(100% - 24px);
}

:global(.cui[data-theme='light']) .valueCol1 {
  color: var(--cui-color-gray-700);
}

.valueCol2 {
  width: 24px;
  align-self: center;
}

.valueCol2 :global(button) {
  color: var(--cui-color-white) !important;
  transition: color 0.2s;
}

.valueCol2 :global(button:hover) {
  color: var(--cui-color-gray-400) !important;
}

:global(.cui[data-theme='light']) .valueCol2 :global(button) {
  color: var(--cui-color-gray-700) !important;
  transition: color 0.2s;
}

:global(.cui[data-theme='light']) .valueCol2 :global(button:hover) {
  color: var(--cui-color-black) !important;
  transition: color 0.2s;
}
