:global(.cui) .root {
  --cui-header-background: var(--cui-color-midnight);
  color: var(--cui-color-white);
  justify-content: space-between;
}

.root [data-balloon]:after {
  text-align: center;
}

:global(.cui a).link,
:global(.cui a).link:hover,
:global(.cui a).link:active,
:global(.cui a).link:focus {
  color: var(--cui-color-white);
  font-weight: 200;
  text-decoration: none;
}

.title {
  display: flex;
}

.disabledLink {
  pointer-events: none;
}

.center {
  width: 152px;
}

@media (max-width: 1500px) {
  .linkWrapper {
    display: none;
  }
}
