.root {
  width: 100%;
}

:global(.cui ul) {
  margin: 0;
  padding: 0;
}

.li {
  display: flex;
  flex-direction: row;
  margin-bottom: 15px;
}

.label {
  font-weight: bold;
  flex: 1;
}

.value {
  flex: 1;
}
