.spinner {
  animation: spin 1s infinite linear;
  color: var(--cui-color-warning);
  font-size: large;
  margin: 0 30px 0 10px;
}

@keyframes spin {
  from {
    transform: scale(1) rotate(0deg);
  }
  to {
    transform: scale(1) rotate(360deg);
  }
}
