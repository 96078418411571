.label {
  margin: 2px 4px 2px 0 !important;
}

.container {
  padding: 10px;
  background-color: var(--tb-blue-active);
  overflow-y: auto;
  min-height: 50px;
  max-height: 105px;
}

:global(.cui[data-theme='light']) .container {
  background-color: var(--cui-color-gray-300);
}
