.root {
  display: flex;
  align-items: center;
}

.icon {
  font-size: large;
  margin: 5px;
}

.enabled {
  color: #6abf4b;
}

.disabled {
  color: #e2231a;
}

.unknown {
  font-weight: bold;
}

.label {
  font-size: large;
}
