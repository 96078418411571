.button {
  margin: auto 0;
  display: flex;
  justify-content: center;
  color: var(--cui-color-white) !important;
  font-size: 16px !important;
  padding: 0 calc(var(--cui-spacing-dbl) * 2.5);
}

.wrapper {
  display: flex;
}

.a {
  min-width: 250px;
}
