.handles {
  background: transparent;
  z-index: var(--tb-diagram-z-index-handles);
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}

.selected {
  z-index: var(--tb-diagram-z-index-selected);
  cursor: grab;
  pointer-events: auto !important;
}

.handles .handle {
  position: absolute;
}

.disabledHandles {
  display: none;
}

.handles .handle.topLeft {
  left: -8px;
  top: -8px;
  cursor: nwse-resize;
}

.handles .handle.topRight {
  right: -8px;
  top: -8px;
  cursor: nesw-resize;
}

.handles .handle.bottomLeft {
  left: -8px;
  bottom: -8px;
  cursor: nesw-resize;
}

.handles .handle.bottomRight {
  right: -8px;
  bottom: -8px;
  cursor: nwse-resize;
}

.colorSky:hover:not(.dashedStroke) {
  box-shadow: 0 0 4px 0 var(--cui-color-sky);
}

:global(.cui[data-theme='light']) .colorGray200:not(:hover) {
  border-color: var(--cui-color-gray-600);
}

.root {
  border: 2px solid var(--cui-color-gray-200);
  pointer-events: none;
}

:global(.cui[data-theme='light']) .root {
  border-color: var(--cui-color-gray-600);
}

.dashedStroke {
  border-style: dashed;
}

.colorDanger,
:global(.cui[data-theme='light']) .colorDanger {
  border-color: var(--cui-color-danger);
}

.colorSuccess,
:global(.cui[data-theme='light']) .colorSuccess {
  border-color: var(--cui-color-success);
}

.colorWarningAlt,
:global(.cui[data-theme='light']) .colorWarningAlt {
  border-color: var(--cui-color-warning-alt);
}

.colorWarning,
:global(.cui[data-theme='light']) .colorWarning {
  border-color: var(--cui-color-warning);
}

.colorOcean,
:global(.cui[data-theme='light']) .colorOcean {
  border-color: var(--cui-color-ocean);
}

.colorLink,
:global(.cui[data-theme='light']) .colorLink {
  border-color: var(--cui-color-link);
}

.colorSky,
:global(.cui[data-theme='light']) .colorSky {
  border-color: var(--cui-color-sky);
}

.colorBlack,
:global(.cui[data-theme='light']) .colorBlack {
  border-color: var(--cui-color-black);
}

.colorGray200,
:global(.cui[data-theme='light']) .colorGray200 {
  border-color: var(--cui-color-gray-200);
}

.colorGray400,
:global(.cui[data-theme='light']) .colorGray400 {
  border-color: var(--cui-color-gray-400);
}

.colorGray600,
:global(.cui[data-theme='light']) .colorGray600 {
  border-color: var(--cui-color-gray-600);
}

.colorGray800,
:global(.cui[data-theme='light']) .colorGray800 {
  border-color: var(--cui-color-gray-800);
}
