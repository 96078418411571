.grid .hidden .icon {
  opacity: 0;
}

.grid .icon {
  color: var(--cui-color-white) !important;
}

.grid :global(.ag-row-hover) .hidden .icon {
  opacity: 1;
}

.grid :global(.ag-cell-editor) {
  border-radius: 3px;
  border: 1px solid var(--cui-accent-color);
  box-shadow: 0 0 0 var(--cui-focus-thickness)
    rgba(var(--cui-focus-color), var(--cui-focus-opacity));
}

.grid :global(.ag-text-field-input:focus-visible),
.grid :global(.ag-cell-value:focus-visible) {
  outline: none;
}

.grid .icon:focus-visible {
  border-radius: 3px;
  box-shadow: 0 0 1px 2px var(--cui-color-white);
  opacity: 1;
}

.grid :global(.ag-cell-edit-wrapper) {
  padding: 4px;
}

.grid :global(.ag-text-field-input) {
  height: 35px !important;
  padding: 4px !important;
  width: 100%;
  background: transparent;
  border: 0;
}

.grid :global(.icon-dropdown-reverse) {
  transform: rotate(180deg);
}

.grid :global(.ag-cell-not-inline-editing) {
  display: flex !important;
  padding-left: 12px !important;
}

.grid :global(.ag-row) {
  border-radius: 3px;
  background-color: var(--tb-blue-active);
  transition: background-color 0.3s !important;
}

.grid :global(.ag-row-odd) {
  background-color: transparent;
}

.grid :global(.ag-row-even) {
  background-color: var(--tb-blue-active);
}

.grid :global(.ag-row):hover {
  background-color: var(--tb-light-blue);
}

.grid :global(.ag-row input) {
  padding-left: 10px !important;
}

.grid :global(.ag-ltr .ag-header-cell-resize) {
  border-left: 1px solid var(--tb-light-blue);
  height: 28px;
  top: 10px;
}

.grid :global(.ag-cell-label-container) {
  padding: 5px 12px;
  font-weight: bold;
}

.grid :global(.cell-fail.ag-cell-not-inline-editing) {
  border: 2px solid var(--cui-theme-danger) !important;
  border-radius: 2px !important;
}

.grid :global(.cell-warn-text.ag-cell-not-inline-editing) {
  color: var(--cui-theme-warning) !important;
}

:global(body[data-theme='light']) .grid :global(.ag-row):nth-of-type(odd) {
  background-color: var(--cui-color-gray-200);
}

:global(body[data-theme='light']) .grid :global(.ag-row):nth-of-type(even) {
  background-color: var(--cui-color-white);
}

:global(body[data-theme='light']) .grid :global(.ag-row):hover {
  background-color: var(--cui-color-gray-100);
}

:global(body[data-theme='light']) .grid :global(.ag-row) .icon span {
  color: var(--cui-color-black);
}

:global(body[data-theme='light'])
  .grid
  :global(.ag-ltr .ag-header-cell-resize) {
  border-left: 1px solid var(--cui-color-gray-500);
}
