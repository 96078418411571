.icon {
  font-size: 18px;
  color: var(--cui-color-warning);
}

.iconDisabled {
  color: var(--cui-color-gray-600);
}

:global(.cui button).pushButton {
  padding: 0;
  margin: 0 30px 0 10px;
  color: var(--cui-color-warning);
  background-color: transparent;
  font-size: small;
  width: 40px;
  border: none;
  cursor: pointer;
}

:global(.cui button).pushUpdatesButton {
  width: 90px;
}

.pushButton:hover {
  font-weight: bold;
}

:global(.cui button).pushButtonDisabled {
  color: var(--cui-color-gray-400);
}

.buttonWrapperDisabled {
  cursor: not-allowed;
  border: none;
}

.root {
  display: flex;
  min-width: 150px;
  justify-content: flex-end;
  align-items: center;
}
