:global(.cui) .root :global(.modal__dialog) {
  width: 1300px;
}

:global(.cui) .root :global(.modal__content) {
  height: auto;
  width: 100%;
}

:global(.cui) .root :global(.modal__title h2) {
  --cui-modal-title-font-size: 30px;
}

.container {
  margin: 0 auto;
  padding-top: 20px;
}

.colsContainer {
  padding-top: 20px;
  border-top: 1px solid var(--cui-theme-primary-darker);
  margin: 10px;
  display: flex;
}

.col {
  flex: 1;
  margin-right: 0;
}

.col:not(:first-child) {
  padding-left: 30px;
}

.col:not(:last-child) {
  padding-right: 30px;
}

.col:not(:last-child) {
  border-right: 1px solid var(--cui-theme-primary-darker);
}

.architectureLabels {
  margin: 2px 4px 2px 0 !important;
}

.description {
  padding-top: 0;
  padding-bottom: 10px;
}

.descriptionLabel {
  font-weight: bold;
  margin-right: 15px;
}

.userEnabled {
  padding-top: 10px;
}

.archLabel {
  margin-left: 10px;
  padding-top: 10px;
}

.archContainer {
  padding: 4px 0 10px 0;
}

.switch {
  min-height: 100px;
  padding: 25px 5px !important;
  width: 150px !important;
}

.lastUpdatedField {
  margin-bottom: 6px !important;
}
