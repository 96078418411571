.root {
    margin-bottom: var(--cui-spacing-dbl);
  }
  
  :global(.cui) .service {
    width: calc(var(--cui-spacing-dbl) * 6);
  }
  
  :global(.cui) .protocol {
    width: calc(var(--cui-spacing-dbl) * 6);
  }
  
  :global(.cui) .port {
    width: calc(var(--cui-spacing-dbl) * 6);
  }