.root {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 120%;
  cursor: default;
}

.icon {
  padding-left: 10px;
  padding-right: 10px;
  font-size: large;
}
