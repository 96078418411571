.cui {
  background-color: #fff;
  max-width: none;
}

.cui[data-theme='dark'] #canvas {
  background: var(--tb-diagram-background-colour);
}

.cui[data-theme='dark'] {
  background: linear-gradient(
    0deg,
    rgba(6, 15, 30, 1) -1%,
    rgba(15, 33, 58, 1) 100%
  );
  background-size: 400% 400%;
  background-position: 0% 50%;
}

.cui .footer a {
  color: var(--cui-font-color);
}

:root .cui .table {
  --tb-table-row-hover: var(--cui-background-active);
}

:root .cui[data-theme='dark'] .table {
  --tb-table-row-hover: var(--tb-light-blue);
  --cui-table-striping-even: transparent;
}

.cui .cui[data-theme='dark'] .table .btn.btn--link {
  color: var(--cui-color-white);
}

.cui[data-theme='dark'] .checkbox,
.cui[data-theme='dark'] .form-group,
.cui[data-theme='dark'] .radio,
.cui[data-theme='dark'] .select,
.cui[data-theme='dark'] .switch {
  --cui-form-background: var(--tb-dark-background);
}

.cui[data-theme='dark'] .step {
  --cui-background-color: var(--tb-dark-background);
}

.cui .btn.btn--link,
.cui .btn.btn--link:hover {
  color: var(--tb-btn-link-colour);
}

.cui .label + .label {
  margin-left: 1rem;
}

.cui .form-group.form-group--error .form-group__text select,
.cui .form-group.form-group--error .form-group__text select:hover,
.cui .form-group.form-group--error .form-group__text select:focus {
  border-color: var(--cui-theme-danger);
}

.cui .form-group.form-group--error .form-group__text select:focus {
  box-shadow: 0 0 0 var(--cui-focus-thickness)
    rgba(var(--cui-theme-danger-rgb), var(--cui-focus-opacity));
}

.cui .panel {
  background-color: var(--tb-card-background);
  --cui-panel-border-radius: 25px;
}

.cui .panel:hover {
  background-color: var(--tb-card-hover-background);
}

.cui .footer a {
  color: var(--cui-color-white);
}

.cui .header,
.cui[data-theme='dark'] .header,
.cui .footer,
.cui footer {
  background-color: var(--tb-darkest-blue);
  border-top: none;
}

.cui .switch input.checked ~ .switch__input:after,
.cui .switch input:checked ~ .switch__input:after {
  border-color: transparent;
}

/* Form */

.cui .form-group .form-group__text input,
.cui .form-group .form-group__text select,
.cui .form-group .form-group__text textarea,
.cui .form-group .form-group__text.select select {
  max-height: none;
  padding: 15px;
  height: auto;
  border-radius: 3px;
  background-color: var(--cui-color-gray-300);
  border-color: transparent;
}

.cui[data-theme='dark'] .form-group .form-group__text input,
.cui[data-theme='dark'] .form-group .form-group__text textarea,
.cui[data-theme='dark'] .form-group .form-group__text select {
  background-color: var(--tb-blue-active);
}
.cui[data-theme='dark'] .form-group .form-group__text input[type='number'] {
  background-color: transparent;
}

.cui[data-theme='dark'] .form-group.form-group--error .form-group__text input,
.cui[data-theme='dark']
  .form-group.form-group--error
  .form-group__text
  textarea {
  border-color: var(--cui-theme-danger);
}

.cui footer.ReactQueryDevtools {
  border-top: 0;
  margin-top: 0;
}
