.addButton {
  color: var(--cui-color-white) !important;
}

.tag {
  background: black;
  display: inline-block;
  padding: 1px 16px;
  margin: 4px;
  border-radius: 3px;
  color: white;
}

.green {
  color: var(--cui-color-success);
  font-size: 2rem !important;
}

.red {
  color: var(--cui-color-danger);
  font-size: 1.9rem !important;
}

:global .ag-cell-not-inline-editing {
  padding: 12px 8px !important;
}
