.root {
  background-color: var(--cui-background-color);
  padding: var(--cui-spacing) var(--cui-spacing-dbl);
  border-radius: var(--cui-border-radius-round);
  display: flex;
  flex-direction: row;
  height: 13rem;
}

:global(.cui[data-theme='dark']) .root {
  background-color: var(--tb-darkest-blue);
}

.root :global(.btn) {
  align-self: center;
}

.root :global(.form-group__text label) {
  align-self: flex-start;
}

:global(.cui) .root :global(.form-group) {
  margin: 0 2rem 0 0;
}
