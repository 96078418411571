:global(.cui .form-group).checkbox {
  margin-top: var(--cui-spacing);
}

.root {
  display: flex;
}

.hide {
  display: none;
}
