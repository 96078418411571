.actionsButton {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  border: none;
  margin-left: 5px;
  margin-right: 5px;
  white-space: nowrap;
  outline: none;
}

:global(.cui button).actionsButton {
  background-color: #1f3346;
}

:global(.cui[data-theme='light'] button).actionsButton {
  background-color: white;
}

:global(.cui button).actionsButton:hover,
:global(.cui button).actionsButton:focus-visible {
  color: var(--cui-color-sky);
  cursor: pointer;
}

.icon {
  padding-left: 5px;
  padding-right: 5px;
  font-size: large;
}
