:global(.cui[data-theme='dark']) .dropdownItemButton {
  color: var(--cui-color-gray-500);
  background-color: var(--cui-color-gray-800);
}

:global(.cui[data-theme='dark']) .dropdownItemButton:hover {
  background-color: var(--cui-color-gray-700);
  color: var(--cui-active-color);
}

:global(.cui[data-theme='light']) .dropdownItemButton {
  color: var(--cui-inactive-color);
  background-color: var(--cui-background-color);
  transition: color 0.5s var(--cui-animate-timing-function),
    background-color 0.25s var(--cui-animate-timing-function),
    box-shadow 0.5s var(--cui-animate-timing-function);
}

:global(.cui[data-theme='light']) .dropdownItemButton:hover {
  background-color: var(--cui-background-hover);
}

.dropdownItemButton {
  width: 100%;
  border: none;
  height: var(--cui-dropdown-menu-item-height);
  cursor: pointer;
  text-align: left;
}

.disabledDropdownItemButton {
  text-align: left;
  height: var(--cui-dropdown-menu-item-height);
  cursor: not-allowed;
  pointer-events: auto !important;
  width: 100%;
}
