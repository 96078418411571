.questionField  {
    width: 900px;
}

.disabled {
    cursor: not-allowed;
    opacity: .5;
}

.hidden {
    visibility: hidden;
}

.scenarioOptionsForm {
    background: var(--sidebar-background-color);
    width: 900px;
    padding: 16px;
    border-radius: 8px;
    margin: 16px;
}

:global(.cui[data-theme='light']) .scenarioOptionsForm {
    background: var(--cui-color-gray-100);
}

.footer {
    color: var(--cui-color-gray-500);
    font-size: 1.32rem;
}

.scenarioOption {
    padding: 0 10px;
    width: 352px;
}
