.configureGrid {
  display: grid;
  grid-template-columns:
    190px minmax(270px, 290px) 450px
    40px 160px 220px 80px 40px;
  padding: 5px;
}

.configureGrid > div {
  height: 90px;
  align-self: start;
  margin-bottom: 0;
  padding: 5px;
  border-top: 1px solid var(--cui-theme-dark);
}

.headerRow {
  display: grid;
  grid-template-columns:
    190px minmax(270px, 290px) 450px
    40px 160px 260px 40px 40px;
  padding: 5px;
}

.headerRow > div {
  height: 30px;
  align-self: start;
  margin-bottom: 2px;
  padding: 5px;
}

.flex {
  display: flex;
  height: calc(100% - 17px);
  align-items: center;
  justify-content: center;
}

.duplicateIcon {
  font-size: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100% - 19px);
  color: var(--cui-color-warning);
}

.content {
  max-width: 95%;
  overflow: auto;
}

.networkSelect:after {
  top: 32px !important ;
  right: 15px !important;
}
