.root {
  min-height: 50px;
  position: sticky;
  bottom: 0;
  background-color: var(--tb-blue-active);
}

.innerWrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: baseline;
  height: 50px;
}
