.root {
  display: flex;
  background-color: var(--tb-darkest-blue);
  margin: 0 20px;
  padding: 5px;
  align-self: center;
  border-radius: var(--cui-border-radius-round);
  justify-content: center;
}

.cursorPointer {
  cursor: pointer;
}

.cursorDisabled {
  cursor: not-allowed;
}

.icon {
  margin-left: 5px;
  align-self: baseline;
}

.label {
  margin: 0 5px;
  font-size: 95%;
  align-self: baseline;
  padding: 0 5px;
  font-weight: bold;
}

:global(.cui[data-theme='light']) .label {
  color: var(--cui-color-white);
}

.iconSuccess {
  color: var(--cui-color-success);
}

.iconWarning {
  color: var(--cui-color-warning);
}
