.root {
  display: flex;
}

.filterColumns {
  display: flex;
}

.heading {
  font-size: 120%;
  font-weight: 700;
  margin-right: 8px;
}

.count {
  font-size: 80%;
  margin-right: 8px;
}

:global(.cui .accordion > li a.accordion__title) {
  justify-content: center;
  align-items: center;
}

.accordionContentOpen {
  border-top: 1px solid var(--cui-inactive-color);
}

.buttonLinks {
  text-align: right;
  margin-bottom: 8px;
  color: silver;
}
