.gallery {
  display: flex;
  flex-wrap: wrap;
  overflow-y: auto;
  min-height: 150px;
  max-height: 600px;
  margin-bottom: 20px;
}

.customIcon {
  width: 80px;
  height: 60px;
  cursor: pointer;
}
