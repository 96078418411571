.root {
  display: flex;
}

:global(.cui) .root :global(.form-group) {
  margin-bottom: var(--cui-spacing);
}

.iconGroup {
  display: flex;
  align-items: center;
  margin-top: 23px;
}

.vmIcon {
  width: 100px;
  height: 100px;
  min-width: 100px;
  min-height: 100px;
}

.buttonWrapper {
  margin-left: 20px;
  white-space: nowrap;
}
