:global(.cui .footer).footer {
  margin-top: 0;
}

.content {
  flex: 1;
}

.footer {
  min-height: 50px;
}

.root {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 50px);
  margin-top: 50px;
}
