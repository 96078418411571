.button {
  margin: auto 0;
  display: flex;
  justify-content: center;
  color: var(--cui-color-white) !important;
  font-size: 16px !important;
  padding: 0 calc(var(--cui-spacing-dbl) * 2.5);
}

.wrapper {
  display: flex;
}

.a {
  min-width: 290px;
}

.reset {
  width: 120px;
  color: var(--cui-color-white);
  border-color: var(--cui-color-white);
  background: none;
}

.reset:disabled {
  color: var(--cui-color-gray-600) !important;
  border-color: var(--cui-color-gray-600);
}

:global(.cui[data-theme='light']) .reset:not(:disabled) {
  border-color: var(--cui-color-gray-900);
  color: var(--cui-color-black) !important;
}
