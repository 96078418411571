.root {
  display: flex;
}

.verticalAlign {
  align-self: center;
}

.targetField {
  width: calc(var(--cui-spacing) * 15);
}

.ipAddress {
  width: calc(var(--cui-spacing) * 11);
}

.alert {
  height: 56px;
  margin-top: 24px !important;
}
