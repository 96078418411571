.hidden {
  stroke: transparent;
  stroke-width: 10;
}

.hidden:hover {
  stroke: var(--cui-color-gray-400);
  opacity: 0.3;
}

.selected {
  opacity: 0.3;
  stroke: var(--cui-color-gray-400);
}

:global(.cui[data-theme='light']) .hidden:hover {
  stroke: var(--cui-color-gray-500);
  opacity: 0.3;
}
