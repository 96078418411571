:root {
  --tb-light-blue: #2f4a70;
  --tb-light-blue-grey: #adb5bd;
  --tb-blue: #0f2031;
  --tb-blue-active: #162f47;
  --tb-darkest-blue: #07101d;
  --tb-btn-link-colour: var(--cui-font-color);
  --tb-card-background: var(--cui-color-gray-200);
  --tb-card-hover-background: #e0f7fe;
  --tb-dark-background: #0f2031;
  --tb-faded-font-colour: var(--cui-color-gray-600);
  --tb-dcloud-logo-colour: #017cad;
  --tb-hero-background: #e6f5fb;
  --tb-content-width: 1200px;
  --tb-configuration-form-width: 700px;
  --tb-header-btn-height: 35px;
  --cui-header-background: var(--cui-color-midnight);
  --tb-modal-width-small: 650px;
  --tb-promoted-label-background: #9b51e0;
  --tb-help-button-padding-bottom: 80px;
  --tb-diagram-z-index-left-panel: 8;
  --tb-diagram-z-index-menu: 7;
  --tb-diagram-z-index-loader: 6;
  --tb-diagram-z-index-edit: 5;
  --tb-diagram-z-index-handles: 4;
  --tb-diagram-z-index-selected: 3;
  --tb-diagram-z-index-text-nodes: 2;
  --tb-diagram-z-index-asset-nodes: 1;
  --tb-diagram-z-index-drawing-nodes: 0;
  --tb-diagram-text-font-size: 14px;
  --tb-diagram-text-width: 520px;
  --tb-diagram-offset-scroll-height: 8px;
  --tb-diagram-top-menu-height: 50px;
  --tb-diagram-icon-radial-gradient-start-percentage: 30%;
  --tb-diagram-icon-radial-gradient-end-percentage: 70%;
  --tb-diagram-background-colour: rgba(15, 33, 58, 1);
}

:root .cui[data-theme='dark'] {
  --tb-btn-link-colour: var(--cui-color-white);
  --tb-card-background: var(--tb-dark-background);
  --tb-card-hover-background: #162e47;
  --tb-faded-font-colour: var(--cui-color-gray-500);
  --tb-dcloud-logo-colour: var(--cui-color-white);
  --tb-hero-background: #0e284d;
  --tb-table-striping-odd: #1d2b38;
  --tb-session-view-actions-background: #1f3346;
}
